
import { defineComponent, ref } from 'vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import ChatEmail from '@/components/shared/chat/ChatEmail.vue'
import { getTableData } from '@/services/tableService'

export default defineComponent({
  components: {
    TmButton,
    TmDropdown,
    ChatEmail,
    TmScrollbar,
  },
  setup() {
    const chatLog = ref(getTableData('chatsEmail')[0].chatLog)

    return {
      chatLog,
    }
  },
})

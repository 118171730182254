
import { defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import type { PersonType } from '@/definitions/shared/types'
import { formatDate } from '@/services/dateTimeService'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import ChatEmailToDropdown from '@/components/shared/chat/email/ChatEmailToDropdown.vue'
import { singleWindow } from '@/services/singleWindow'

export default defineComponent({
  components: {
    ChatEmailToDropdown,
    TmAvatar,
    TmDropdownItem,
    TmButton,
    TmButtonMore,
    TmDropdown,
  },
  props: {
    sender: {
      type: Object as PropType<PersonType>,
    },
    text: {
      type: String,
    },
    date: {
      type: String,
    },
    to: {
      type: Array as PropType<PersonType[]>,
    },
  },
  setup() {
    const dropdownState = ref<boolean>(false)
    const showEmail = () => {
      singleWindow(900, 600, '/view-original-email')
    }

    return {
      showEmail,
      dropdownState,
      formatDate,
    }
  },
})


import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { PersonType } from '@/definitions/shared/types'

export default defineComponent({
  props: {
    to: {
      type: Array as PropType<PersonType[]>,
    },
  },
})
